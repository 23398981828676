import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Col, Row, Card, Form, Spinner } from 'react-bootstrap';
import { Error } from '../functions';

const Welcome = () => {

    const { code } = useParams();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [industry, setIndustry] = useState('');
    const [budget, setBudget] = useState('');
    const [deadline, setDeadline] = useState('');
    const [workCat, setWorkCat] = useState('');
    const [anythingElse, setAnythingElse] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleNameChange = (e) => { setName(e.target.value); };
    const handlePhoneChange = (e) => { setPhone(e.target.value); };
    const handleEmailChange = (e) => { setEmail(e.target.value); };
    const handleIndustryChange = (e) => { setIndustry(e.target.value); };
    const handleBudgetChange = (e) => { setBudget(e.target.value); };
    const handleDeadlineChange = (e) => { setDeadline(e.target.value); };
    const handleWorkCatChange = (e) => { setWorkCat(e.target.value); };
    const handleAnythingElseChange = (e) => { setAnythingElse(e.target.value); };

    let nav = useNavigate();

    const handleClick = (e) => 
    {
        e.preventDefault();
        setButtonLoading(true);
        postData();
    }

    const postStats = async () => 
    {
        if(code != undefined && code !== "")
        {
            var body = JSON.stringify({ 
                code: code
            });

            await fetch(`/clicks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            }).then(response => response.json()).then(data => 
            {
                if(data.error){setMessage(data.message)}
            }).catch(error => 
            {
                console.log('Error posting data', error);
            }); 
        }
        else
        {
            // Show error here
            setMessage('Referral code missing')
        }
    };

    useEffect(() => {
        postStats();
    }, []);

    async function postData() 
    {
        if(code != undefined && code !== "")
        {
            var body = JSON.stringify({ 
                code: code,
                name: name,
                phone: phone,
                email: email,
                industry: industry,
                budget: budget,
                deadline: deadline,
                workCat: workCat,
                anythingElse: anythingElse
            });

            await fetch(`/leads`, 
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            }).then(response => response.json()).then(data => 
            {
                setButtonLoading(false);
                if(data.error){setMessage(data.message)}
                else
                {
                    nav('/welcome/complete');
                }
            }).catch(error => 
            {
                console.log('Error posting data', error);
            }); 
        }
        else
        {
            // Show error here
            setMessage('Referral code missing')
        }
    }

    return (
        <div>
            <Error heading={`ERROR`} message={message}/>
            <Row className="align-items-center">
                <Col xs={12} lg={6} className="text-lg-start text-center pt-8 pb-6 order-0 position-relative">
                    <div>
                        <h1 className="fs-3 fs-lg-2 fs-md-1 fs-lg-2 fs-xl-1 fw-black mb-4">
                            {/* <span className="text-primary me-3">Welcome!</span> */}
                            {/* <br /> */}
                            Take your first step to brand success with Detour Inc.
                        </h1>
                    </div>
                </Col>
            </Row>
            <Card>
                <Row className="g-0">
                <Col>
                    <Card.Body>
                        <Card.Title as="h4">Answer a few questions to get started</Card.Title>
                        <Form.Group className="mb-3">
                            <Form.Label>First & Last Name</Form.Label>
                            <Form.Control type="text" placeholder="" value={name} onChange={handleNameChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number (Format: 123-456-7890)</Form.Label>
                            <Form.Control type="tel" placeholder="" value={phone} onChange={handlePhoneChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="" value={email} onChange={handleEmailChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Industry</Form.Label>
                            <Form.Control type="text" placeholder="" value={industry} onChange={handleIndustryChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Budget (Numbers only)</Form.Label>
                            <Form.Control type="num" placeholder="" value={budget} onChange={handleBudgetChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Deadline</Form.Label>
                            <Form.Control type="date" placeholder="" value={deadline} onChange={handleDeadlineChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Work Category</Form.Label>
                            <Form.Select value={workCat} onChange={handleWorkCatChange}>
                                <option value=""></option>
                                <option value="branding">Branding</option>
                                <option value="print">Print</option>
                                <option value="advertising">Advertising</option>
                                <option value="photovideo">Photography/Video</option>
                                <option value="tradeshow">Tradeshow/Event</option>
                                <option value="other">Other</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Anything else you want to share?</Form.Label>
                            <Form.Control as="textarea" rows={3} value={anythingElse} onChange={handleAnythingElseChange}/>
                        </Form.Group>
                        <Card.Text>
                            <small className="text-muted">We will never share your data with anyone else.</small>
                        </Card.Text>
                        <Link className="btn btn-outline-info" onClick={handleClick}>Submit</Link>
                        {
                            buttonLoading
                            ?
                            <Spinner animation="border" variant="primary" />
                            :
                            <></>
                        }
                    </Card.Body>
                </Col>
                </Row>
            </Card>
        </div>
    );
};

export default Welcome;

