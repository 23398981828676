import { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { faClipboard, faBarChart, faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, Spinner, Container } from 'react-bootstrap';
import AvatarUpload from 'components/common/AvatarUpload';
import DefaultAvatar from 'assets/img/team/AvatarDefault.svg';
import { renderPhoneNumber } from '../functions';
import { Error } from '../functions';
import {UilEnvelopeUpload,UilLinkBroken,UilEye,UilCheckCircle,UilNewspaper} from '@iconscout/react-unicons';
import Unicon from 'components/base/Unicon';
import QRCode from "react-qr-code";

const StandardDashboard = () => 
{
    const { referralCode } = useParams();
    const [data, setData] = useState(null);
    const [statsData, setStatsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [isErroring, setIsErroring] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyToClipboard = () => 
    {
        if (referralCode) 
        {
            navigator.clipboard.writeText(`https://leadsandleaders.com/share/${referralCode}`);
            setIsCopied(true);
            // Reset isCopied to false after 3 seconds
            setTimeout(() => 
            {
                setIsCopied(false); 
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    };

    var value = `https://leadsandleaders.com/share/${referralCode}`;

    const fetchData = async () => 
    {
        try 
        {
            if(referralCode != undefined && referralCode !== "")
            {
                const response = await fetch(`/codes/${referralCode}/profile`);
                const result = await response.json();
                if(result.length == 0) 
                {
                    setIsErroring(true);
                    setMessage('Invalid referral code.')
                }
                else
                {
                    setData(result);
                }
                
                const statsResponse = await fetch(`/stats/${referralCode}`);
                const statsResults = await statsResponse.json();
                if(statsResults.length == 0) {setMessage('Invalid referral code.')}
                else
                {
                    setStatsData(statsResults);
                }
                setIsLoading(false);
            }
            else
            {
                // Show error here
                setMessage('Referral code missing. Please provide it by going to the homepage.')
            }
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Error heading={`ERROR`} message={message}/>
            <h3 className="mb-0 text-left">
                <span className="d-block mb-3 text-primary">
                    <span className="d-md-inline d-lg-inline"> {/* Visible on medium and large screens */}
                        {referralCode}
                    </span>
                    <span className="d-none d-md-none d-lg-none"> {/* Hidden on medium and large screens */}
                        {referralCode}
                    </span>
                </span>
            </h3>
            {
                isLoading
                ?
                <><Spinner animation="border" variant="primary" /></>
                :
                <>
                {
                    isErroring
                    ?
                    <>
                    <Link className='btn btn-outline-secondary' to="/">
                        <FontAwesomeIcon className="me-2" icon={faArrowLeft} />
                        Back
                    </Link>
                    </>
                    :
                    <>
                    <div className="pt-5 mb-9">
                        <Row className="align-items-center justify-content-between g-3 mb-4">
                            <Col xs="auto">
                                <h2 className="mb-0">My Info</h2>
                            </Col>
                            <Col xs="auto" className="d-flex flex-wrap gap-2 gap-sm-3">
                                <Link className='btn btn-outline-secondary' to="/leaderboard">
                                    <FontAwesomeIcon className="me-2" icon={faBarChart} />
                                    Leaderboard
                                </Link>
                            </Col>
                        </Row>
                        <Row className="g-3 mb-6">
                            <Col xs={12} lg={8}>
                            <Card className="h-100">
                            <Card.Body>
                                <div className="border-bottom border-dashed border-300 pb-4">
                                <Row className="align-items-center g-3 g-sm-5 text-center text-sm-start">
                                    <Col xs={12} sm="auto">
                                        <AvatarUpload size="5xl" src={DefaultAvatar} />
                                    </Col>
                                    <Col xs={12} sm="auto" className="flex-1">
                                        <h3>{`${data[0].FirstName} ${data[0].LastName}`}</h3>
                                        <p className="text-800">Joined {`${String(data[0].DateCreated).split("T")[0]}`}</p>
                                    </Col>
                                </Row>
                                </div>
                                <div className="d-flex flex-between-center pt-4">
                                    <Container>
                                        <Row>
                                            <Col>
                                                <h6 className="mb-2 text-800">My URL</h6>
                                                <h5 className="fs-7 text-warning text-1000 mb-0">
                                                    <span className="d-block d-md-inline">https://leadsandleaders.com/share/</span>
                                                    <span className="d-block d-md-inline">{referralCode}</span>
                                                </h5>
                                                <br></br>
                                                {
                                                    isCopied
                                                    ?
                                                    <>
                                                    <Link className='btn btn-outline-primary' onClick={handleCopyToClipboard}>
                                                        Copied!
                                                        &nbsp;
                                                        <FontAwesomeIcon className='me-1' icon={faCheck} />
                                                    </Link>
                                                    </>
                                                    :
                                                    <>
                                                    <Link className='btn btn-outline-primary' onClick={handleCopyToClipboard}>
                                                        Copy To Clipboard
                                                        &nbsp;
                                                        <FontAwesomeIcon className='me-1' icon={faClipboard} />
                                                    </Link>
                                                    </>
                                                }
                                            </Col>
                                            <Col>
                                            <h6 className="mb-2 text-800">My QR Code</h6>
                                            <QRCode
                                                size={64}
                                                value={value}
                                                viewBox={`0 0 64 64`}
                                            />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Card.Body>
                            </Card>
                            </Col>
                            <Col xs={12} lg={4}>
                                <Card className="h-100">
                                    <Card.Body>
                                        <div className="border-bottom border-dashed border-300 mb-4">
                                            <h4 className="mb-3 lh-sm lh-xl-1">
                                                Contact Info
                                            </h4>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <h5 className="text-1000">Address</h5>
                                            <p className="text-800">
                                                {`${data[0].Street}`}
                                                <br />
                                                {`${data[0].City}, ${data[0].State} ${data[0].Zip}`}
                                            </p>
                                        </div>    
                                        <div>
                                            <div className="d-flex justify-content-between gap-2 mb-3">
                                                <h5 className="text-1000 mb-0">Email</h5>
                                                <a className="lh-1" href={`mailto:${data[0].Email}`}>
                                                    {data[0].Email}
                                                </a>
                                            </div>
                                            <div className="d-flex justify-content-between gap-2">
                                                <h5 className="text-1000 mb-0">Phone</h5>
                                                <a className="text-800" href={`tel:+1${data[0].PhoneNum}`}>
                                                    {renderPhoneNumber(data[0].PhoneNum)}
                                                </a>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <h2 className="mb-0">Performance Summary</h2>
                        <p>See how your referral code is performing</p>
                        <Card className="h-100">
                            <Card.Body>
                                <Row className="align-items-center justify-content-between">
                                    {/* <Col xs={12} sm={6} md={4} lg={2} className="text-center mb-4 mb-md-0">
                                        <Unicon icon={UilEnvelopeUpload} className={`text-primary mb-1`} size={31.25} />
                                        <h1 className="fs-5 mt-3">{statsData[0].InvitesSent}</h1>
                                        <p className="fs-9 mb-0">Email Invites Sent</p>
                                    </Col> */}
                                    {/* <Col xs={12} sm={6} md={4} lg={2} className="text-center mb-4 mb-md-0">
                                        <Unicon icon={UilEye} className={`text-primary mb-1`} size={31.25} />
                                        <h1 className="fs-5 mt-3">{statsData[0].InvitesViewed}</h1>
                                        <p className="fs-9 mb-0">Invites Viewed</p>
                                    </Col> */}
                                    <Col xs={12} sm={6} md={4} lg={2} className="text-center mb-4 mb-md-0">
                                        <Unicon icon={UilLinkBroken} className={`text-primary mb-1`} size={31.25} />
                                        <h1 className="fs-5 mt-3">{statsData[0].LinkClicks}</h1>
                                        <p className="fs-9 mb-0">Total Link Clicks</p>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={2} className="text-center mb-4 mb-md-0">
                                        <Unicon icon={UilNewspaper} className={`text-primary mb-1`} size={31.25} />
                                        <h1 className="fs-5 mt-3">{statsData[0].FormsSubmitted}</h1>
                                        <p className="fs-9 mb-0">Quote Forms Submitted</p>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={2} className="text-center">
                                        <Unicon icon={UilCheckCircle} className={`text-success mb-1`} size={31.25} />
                                        <h1 className="fs-5 mt-3">{statsData[0].SuccessfulReferrals}</h1>
                                        <p className="fs-9 mb-0">Successful Referrals</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br></br>
                        <Link className='btn btn-outline-secondary' to="/">
                            <FontAwesomeIcon className="me-2" icon={faArrowLeft} />
                            Back
                        </Link>
                    </div>
                    </>
                }
                </>
            }
        </div>
    );
};

export default StandardDashboard;

