import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Form, Spinner, Table, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faRefresh, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { renderPhoneNumber, generateRandomString } from '../functions';

const CreateCode = () =>
{
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [code, setCode] = useState(`DETOUR_${generateRandomString(20)}`);
    let nav = useNavigate();

    const fetchData = async () => 
    {
        try 
        {
            const response = await fetch('/users/assignees');
            const result = await response.json();
            setData(result);
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
        finally 
        {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    function renderUsers()
    {
        const userList = [];
        for(var i=0; i < data.length; i++)
        {
            let currentID = String(data[i].UserOfficialID).trim();
            userList.push(
                <tr>
                    <td>{data[i].FirstName}</td>
                    <td>{data[i].LastName}</td>
                    <td>{data[i].Email}</td>
                    <td>{renderPhoneNumber(data[i].PhoneNum)}</td>
                    <td>
                        <Link key={currentID} className='btn btn-primary' onClick={() => assignNewCode(currentID)}>
                            Assign
                        </Link>
                    </td>
                </tr>
            )
        }
        return userList;
    }

    const generateNewCode = (e) => 
    {
        e.preventDefault();
        setCode(`DETOUR_${generateRandomString(20)}`);
    }

    async function assignNewCode(id)
    {
        var body = JSON.stringify({ 
            userid: id,
            code: code
        });

        console.log(`ID is: ${id}`)
        await fetch('/codes/assign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        }).then(response => response.json()).then(data => 
        {
            if(data.error){alert("There has been an error")}
            else
            {
                nav(`/codes`);
            }
            console.log('Post successful', data);
        }).catch(error => 
        {
            console.log('Error posting data', error);
        }); 
        
    }


    return (
        <div>
            <Row className="g-4">
                <Col xs={12} xxl={6}>
                    <h2 className="mb-2">Create A New Code</h2>
                </Col>
            </Row>
            <br></br>
            <Card bg="Light">
                <Card.Body>
                <Card.Title as="h4" className="Light text-dark" >New Referral Code</Card.Title>
                    <Card.Text className="text-warning">{code}</Card.Text>
                    <Link className='btn btn-outline-primary' onClick={generateNewCode}>
                        <FontAwesomeIcon className='me-1' icon={faRefresh} />
                    </Link>
                    <Link className='btn btn-outline-primary' style={{marginLeft: '5px'}} onClick={() => navigator.clipboard.writeText(code)}>
                        <FontAwesomeIcon className='me-1' icon={faClipboard} />
                    </Link>
                </Card.Body>
            </Card>
            <br></br>
            <h5 className="text-700 fw-semi-bold">
                Choose the user that you would like to assign this new code to below (only users without codes are shown):
            </h5>
            {
                isLoading
                ?
                <><Spinner animation="border" variant="primary" /><br></br><br></br></>
                :
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderUsers()}
                        </tbody>
                    </Table>
                </>
            }
            <Link to="/codes" className="btn btn-outline-info">Back</Link>
        </div>
    );
};

export default CreateCode;

