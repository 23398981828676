import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Spinner, Table } from 'react-bootstrap'
import { Error } from '../functions';

const DeleteCode = () => 
{
    const { codeID } = useParams();

    const [codeData, setCodeData] = useState(null);

    const [buttonLoading, setButtonLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');

    // Use for changing pages
    let nav = useNavigate();

    const fetchData = async () => 
    {
        try 
        {
            const response = await fetch(`/codes/${codeID}`);
            const result = await response.json();
            setCodeData(result);
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
        finally 
        {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    async function deleteData() 
    {
        await fetch(`/codes/${codeID}/delete`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json()).then(data => 
        {
            setButtonLoading(false);
            if(data.error){setMessage(data.message)}
            else
            {
                nav('/codes');
            }
        }).catch(error => 
        {
            console.log('Error posting data', error);
        }); 
    }

    const handleClick = (e) => 
    {
        e.preventDefault();
        setButtonLoading(true);
        deleteData();
    }

    return (
        <div>
            <Error heading={`ERROR`} message={message}/>
            <h2 className="mb-2 text-danger">Delete A Code</h2>
            <br></br>
            {
                isLoading
                ?
                <Spinner animation="border" variant="primary" />
                :
                <>
                <Card key={`danger`} className={'border border-danger'}>
                    <Card.Body>
                        <Card.Title as="h4">Are you sure?</Card.Title>
                        <Card.Text>
                            The user it's linked to will not be removed and this action cannot be undone. The following code information will be deleted:
                        </Card.Text>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>UserID</th>
                                    <th>Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{codeData[0].ID}</td>
                                    <td>{codeData[0].UserID}</td>
                                    <td>{codeData[0].Code}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                </>
            }
            <br></br>
            <Link to="/codes" className="btn btn-outline-info">Back</Link>
            <Link className="btn btn-outline-info" style={{marginLeft: '5px'}} onClick={handleClick}>Confirm</Link>
            <br></br>
            <br></br>
            {
                buttonLoading
                ?
                <Spinner animation="border" variant="primary" />
                :
                <></>
            }
        </div>
    );
};

export default DeleteCode;

