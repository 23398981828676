import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Button, Table, Spinner, Stack } from 'react-bootstrap';
import EcomTotalOrdersCard from '../components/cards/EcomTotalOrdersCard';
import EcomNewCustomersCard from '../components/cards/EcomNewCustomersCard';
import EcomTopCouponsCard from '../components/cards/EcomTopCouponsCard';
import EcomPayingVsNonPayingCard from '../components/cards/EcomPayingVsNonPayingCard';
import EcomLatestReviewsTable from '../components/tables/EcomLatestReviewsTable';
import EcomProjectionVsActualChart from '../components/charts/e-charts/EcomProjectionVsActualChart';
import EomReturningCustomerRateChart from '../components/charts/e-charts/EomReturningCustomerRateChart';
import EcomTopRegionsTable from '../components/tables/EcomTopRegionsTable';
import EcomTopRegionsMap from '../components/leaflet-maps/EcomTopRegionsMap';
import EcomTotalSellsChart from '../components/charts/e-charts/EcomTotalSellsChart';
import EcomStats from '../components/stats/EcomStats';
import { mapMarkerPoints } from '../data/mapMarkerPoints';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignOutButton } from "./SignOutButton";
import { SignInButton } from './SignInButton';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { callMsGraph } from '../graph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircle,
  faPause,
  faSquare,
  faStar,
  faXmark
} from '@fortawesome/free-solid-svg-icons';

const AdminDashboard = () => 
{
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
    .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
    })
    .then((response) => {
        callMsGraph(response.accessToken).then((response) => setGraphData(response));
    });
  }

  function renderGraphData(props)
  {
    return (
      <div id="profile-div">
        <p>
          Welcome {String(props.displayName).split(" ")[0]}
        </p>
      </div>
    );
  }

  const [data, setData] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => 
  {
    try 
    {
      const response = await fetch('/codes');
      const result = await response.json();
      setData(result);

      const statsResponse = await fetch('/admin/stats');
      const statsResult = await statsResponse.json();
      setStatsData(statsResult);

      setIsLoading(false);
    } 
    catch (error) 
    {
      console.error('Error fetching data:', error);
    } 
  };

  useEffect(() => {
    fetchData();
  }, []);

  function renderCodes()
  {
    const codeList = [];
    if(data.length === 0)
    {
      codeList.push(<tr><td>There are no codes currently :/</td><td></td><td></td><td></td><td></td><td></td></tr>);
    }
    else
    {
      for(var i=0; i < data.length; i++)
      {
        codeList.push(
          <tr>
            <td>{data[i].FirstName}</td>
            <td>{data[i].LastName}</td>
            <td>{data[i].Email}</td>
            <td>22</td>
            <td>14</td>
            <td>4</td>
            <td>1</td>
            <td>1</td>
          </tr>
        )
      }
    }
    return codeList;
  }

  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      {
        isLoading
        ?
        <><Spinner animation="border" variant="primary" /></>
        :
        <>
        <div className="pb-5">
          <Row className="g-4">
            <Col xs={12} xxl={6}>
              <div className="mb-8">
                {/* {isAuthenticated ? <SignOutButton /> : <SignInButton />} */}
                <h2 className="mb-2">Referral Code Dashboard <span className='text-primary'>[Admin](Work-In-Progress)</span></h2>
                {graphData ? (
                  renderGraphData(graphData)
                ) : (
                  <></>
                  // <Button variant="secondary" onClick={RequestProfileData}>
                  //   Request Profile Information
                  // </Button>
                )}
                <h5 className="text-700 fw-semi-bold">
                  View the most recent statistics for your code
                </h5>
              </div>
              <Row className="align-items-center g-4 border-bottom pb-4 mb-6">
                <Col xs={12} md="auto" key="1">
                  <Stack direction="horizontal" className="align-items-center">
                  <span
                    className="fa-layers"
                    style={{ minHeight: '46px', minWidth: '46px' }}
                  >
                    <FontAwesomeIcon
                      icon={faSquare}
                      size="2x"
                      className={`text-success-300`}
                      transform="down-4 rotate--10 left-4"
                    />
                    <FontAwesomeIcon
                      icon={faCircle}
                      size="2x"
                      className={`text-success-100 fa-layers-circle`}
                      transform="up-4 right-3 grow-2"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      size="1x"
                      className={`text-success`}
                      transform="shrink-2 up-8 right-6"
                    />
                  </span>
                  <div className="ms-3">
                    <h4 className="mb-0">22 referral codes</h4>
                    <p className="text-800 fs-9 mb-0">Awaiting conversions</p>
                  </div>
                  </Stack>
                </Col>
                <Col xs={12} md="auto" key="2">
                  <Stack direction="horizontal" className="align-items-center">
                  <span
                    className="fa-layers"
                    style={{ minHeight: '46px', minWidth: '46px' }}
                  >
                    <FontAwesomeIcon
                      icon={faSquare}
                      size="2x"
                      className={`text-warning-300`}
                      transform="down-4 rotate--10 left-4"
                    />
                    <FontAwesomeIcon
                      icon={faCircle}
                      size="2x"
                      className={`text-warning-100 fa-layers-circle`}
                      transform="up-4 right-3 grow-2"
                    />
                    <FontAwesomeIcon
                      icon={faPause}
                      size="1x"
                      className={`text-warning`}
                      transform="shrink-2 up-8 right-6"
                    />
                  </span>
                  <div className="ms-3">
                    <h4 className="mb-0">{statsData.totalInvitesSent} invites sent</h4>
                    <p className="text-800 fs-9 mb-0">On hold</p>
                  </div>
                  </Stack>
                </Col>
                <Col xs={12} md="auto" key="3">
                  <Stack direction="horizontal" className="align-items-center">
                  <span
                    className="fa-layers"
                    style={{ minHeight: '46px', minWidth: '46px' }}
                  >
                    <FontAwesomeIcon
                      icon={faSquare}
                      size="2x"
                      className={`text-danger-300`}
                      transform="down-4 rotate--10 left-4"
                    />
                    <FontAwesomeIcon
                      icon={faCircle}
                      size="2x"
                      className={`text-danger-100 fa-layers-circle`}
                      transform="up-4 right-3 grow-2"
                    />
                    <FontAwesomeIcon
                      icon={faXmark}
                      size="1x"
                      className={`text-danger`}
                      transform="shrink-2 up-8 right-6"
                    />
                  </span>
                  <div className="ms-3">
                    <h4 className="mb-0">{statsData.totalInvitesViewed} invites viewed</h4>
                    <p className="text-800 fs-9 mb-0">Good traction</p>
                  </div>
                  </Stack>
                </Col>
              </Row>
              <Row className="justify-content-between align-items-center mb-4 g-3">
                <Col xs="auto">
                  <h3>Total Links Clicked</h3>
                  <p className="text-700 lh-sm mb-0">
                    Sum of all link clicks via email and text
                  </p>
                </Col>
              </Row>
              <EcomTotalSellsChart />
            </Col>
            <Col xs={12} xxl={6}>
              <Row className="g-3">
                <Col xs={12} md={6}>
                  <EcomTotalOrdersCard />
                </Col>
                <Col xs={12} md={6}>
                  <EcomNewCustomersCard />
                </Col>
                <Col xs={12} md={6}>
                  <EcomTopCouponsCard />
                </Col>
                <Col xs={12} md={6}>
                  <EcomPayingVsNonPayingCard />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-7 border-y border-300">
          <h3>Individual Performances</h3>
          <p className="text-700 lh-sm mb-0">Each Referral Code's Stats</p>
          <br></br>
          <Table responsive>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Email Invites Sent</th>
                <th>Invites Viewed</th>
                <th>Total Clicks</th>
                <th>Forms Submitted</th>
                <th>Successful Referrals</th>
              </tr>
            </thead>
            <tbody>
              {renderCodes()}
            </tbody>
          </Table>
        </div>
      </>
      }
    </>
  );
};

export default AdminDashboard;