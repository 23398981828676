import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

const isAdmin = sessionStorage.getItem("Admin");

const adminRoutes: RouteItems[] = 
[
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Home',
        icon: 'home',
        path: '/',
        pathName: 'default-dashboard',
        topNavIcon: 'shopping-cart',
        active: true
      },
      {
        name: 'Admin Dashboard',
        icon: 'grid',
        path: '/admin',
        pathName: 'default-dashboard',
        topNavIcon: 'shopping-cart',
        active: true
      },
      {
        name: 'Users',
        icon: 'users',
        path: '/users',
        pathName: 'test',
        topNavIcon: 'phone',
        active: true
      },
      {
        name: 'Codes',
        icon: 'code',
        path: '/codes',
        pathName: 'test',
        topNavIcon: 'phone',
        active: true
      },
      {
        name: 'Leaderboard',
        icon: 'bar-chart-2',
        path: '/leaderboard',
        pathName: 'leaderboard',
        topNavIcon: 'phone',
        active: true
      },
      {
        name: 'Referrals',
        icon: 'dollar-sign',
        path: '/referrals',
        pathName: 'referrals',
        topNavIcon: 'phone',
        active: true
      },
      {
        name: 'Leads',
        icon: 'inbox',
        path: '/leads',
        pathName: 'leads',
        topNavIcon: 'phone',
        active: true
      }
    ]
  },
]

const normalRoutes: RouteItems[] = 
[
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Home',
        icon: 'home',
        path: '/',
        pathName: 'default-dashboard',
        topNavIcon: 'shopping-cart',
        active: true
      },
      {
        name: 'Leaderboard',
        icon: 'bar-chart-2',
        path: '/leaderboard',
        pathName: 'leaderboard',
        topNavIcon: 'phone',
        active: true
      }
    ]
  },
]

export const routes: RouteItems[] = isAdmin == "true" ? adminRoutes : normalRoutes;