import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

const Layout = ({ children }) => 
{
    const location = useLocation();
    const backgroundClass = 
    (location.pathname === '/' || String(location.pathname).toLowerCase().includes('share')) ? 'customBackground' : '';

    return <div className={classNames('content', backgroundClass)}>{children}</div>;
};

export default Layout;
