import React, { useEffect} from 'react';
import { useMsal } from '@azure/msal-react';

function CheckAuth() 
{
    const { accounts, inProgress } = useMsal();

    useEffect(() => {
        // Put admin into session storage
        if (accounts.length > 0) 
        {
            sessionStorage.setItem("Admin", true);
        } 
        else 
        {
            sessionStorage.setItem("Admin", false);
        }
    }, [accounts]);
}

export default CheckAuth;