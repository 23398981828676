import { Nav, Navbar } from 'react-bootstrap';
import { capitalize } from 'helpers/utils';
import { UilArrowFromRight, UilLeftArrowToLeft} from '@iconscout/react-unicons';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import NavbarTopNav from './NavbarTopNav';
import NavbarVerticalCollapseProvider from './NavbarVerticalCollapseProvider';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}


const adminRoutes: RouteItems[] = 
[
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Home',
        icon: 'home',
        path: '/',
        pathName: 'default-dashboard',
        topNavIcon: 'shopping-cart',
        active: true
      },
      {
        name: 'Admin Dashboard',
        icon: 'grid',
        path: '/admin',
        pathName: 'default-dashboard',
        topNavIcon: 'shopping-cart',
        active: true
      },
      {
        name: 'Users',
        icon: 'users',
        path: '/users',
        pathName: 'test',
        topNavIcon: 'phone',
        active: true
      },
      {
        name: 'Codes',
        icon: 'code',
        path: '/codes',
        pathName: 'test',
        topNavIcon: 'phone',
        active: true
      },
      {
        name: 'Leaderboard',
        icon: 'bar-chart-2',
        path: '/leaderboard',
        pathName: 'leaderboard',
        topNavIcon: 'phone',
        active: true
      },
      {
        name: 'Referrals',
        icon: 'dollar-sign',
        path: '/referrals',
        pathName: 'referrals',
        topNavIcon: 'phone',
        active: true
      },
      {
        name: 'Leads',
        icon: 'inbox',
        path: '/leads',
        pathName: 'leads',
        topNavIcon: 'phone',
        active: true
      }
    ]
  },
]

const normalRoutes: RouteItems[] = 
[
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Home',
        icon: 'home',
        path: '/',
        pathName: 'default-dashboard',
        topNavIcon: 'shopping-cart',
        active: true
      },
      {
        name: 'Leaderboard',
        icon: 'bar-chart-2',
        path: '/leaderboard',
        pathName: 'leaderboard',
        topNavIcon: 'phone',
        active: true
      }
    ]
  },
]


const NavbarVertical = () => 
{
  const isAdmin = sessionStorage.getItem("Admin");

  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarVerticalAppearance,
      isNavbarVerticalCollapsed
    },
    setConfig
  } = useAppContext();

  const { breakpoints } = useBreakpoints();


  // const routes: RouteItems[] = isAdmin == "true" ? adminRoutes : normalRoutes;

  if(isAdmin == "true")
  {
    return (
      <NavbarVerticalCollapseProvider>
        <Navbar className={classNames('navbar-vertical', {'navbar-darker': navbarVerticalAppearance === 'darker'})} expand="lg" variant="">
          <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
            <div className="navbar-vertical-content">
              <Nav className="flex-column" as="ul" id="navbarVerticalNav">
                {adminRoutes.map(route => (
                  <Nav.Item key={route.label}>
                    {!route.labelDisabled && (
                      <>
                        <p className="navbar-vertical-label">
                          {capitalize(route.label)}
                        </p>
                        <hr className="navbar-vertical-line" />
                      </>
                    )}
                    <NavbarVerticalMenu level={1} routes={route.pages} />
                  </Nav.Item>
                ))}
              </Nav>
              {navbarPosition === 'combo' && breakpoints.down('lg') && (
                <div className="move-container">
                  <div className="navbar-vertical-divider">
                    <hr className="navbar-vertical-hr" />
                  </div>
                  <NavbarTopNav />
                </div>
              )}
            </div>
          </Navbar.Collapse>
          <div className="navbar-vertical-footer">
            <Button
              className="navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"
              onClick={() => {
                setConfig({
                  isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed
                });
              }}>
              {isNavbarVerticalCollapsed ? (
                <UilArrowFromRight size={16} className="mb-1" />
              ) : (
                <>
                  <UilLeftArrowToLeft size={16} className="mb-1" />
                  <span className="ms-2">Collapsed View</span>
                </>
              )}
            </Button>
          </div>
        </Navbar>
      </NavbarVerticalCollapseProvider>
    );
  }
  else
  {
    return (
      <NavbarVerticalCollapseProvider>
        <Navbar className={classNames('navbar-vertical', {'navbar-darker': navbarVerticalAppearance === 'darker'})} expand="lg" variant="">
          <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
            <div className="navbar-vertical-content">
              <Nav className="flex-column" as="ul" id="navbarVerticalNav">
                {normalRoutes.map(route => (
                  <Nav.Item key={route.label}>
                    {!route.labelDisabled && (
                      <>
                        <p className="navbar-vertical-label">
                          {capitalize(route.label)}
                        </p>
                        <hr className="navbar-vertical-line" />
                      </>
                    )}
                    <NavbarVerticalMenu level={1} routes={route.pages} />
                  </Nav.Item>
                ))}
              </Nav>
              {navbarPosition === 'combo' && breakpoints.down('lg') && (
                <div className="move-container">
                  <div className="navbar-vertical-divider">
                    <hr className="navbar-vertical-hr" />
                  </div>
                  <NavbarTopNav />
                </div>
              )}
            </div>
          </Navbar.Collapse>
          <div className="navbar-vertical-footer">
            <Button
              className="navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"
              onClick={() => {
                setConfig({
                  isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed
                });
              }}>
              {isNavbarVerticalCollapsed ? (
                <UilArrowFromRight size={16} className="mb-1" />
              ) : (
                <>
                  <UilLeftArrowToLeft size={16} className="mb-1" />
                  <span className="ms-2">Collapsed View</span>
                </>
              )}
            </Button>
          </div>
        </Navbar>
      </NavbarVerticalCollapseProvider>
    );
  }
};

export default NavbarVertical;
