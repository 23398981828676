import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import bg12 from '../assets/img/bg/bg-1-2.png';
// import bg29 from '../assets/img/bg/bg-29.png';
// import bg30 from '../assets/img/bg/bg-30.png';
import bg28 from '../assets/img/bg/Background.png';
import bg31 from '../assets/img/bg/MobileBackground.png';
// import bg23 from '../assets/img/bg/bg-23.png';
// import background from '../assets/img/bg/backgroundLarge.jpg';
// import background from '../assets/img/bg/finalHomeBackground.jpg';


import { Link, useNavigate } from 'react-router-dom';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Error } from '../functions';


const Home = () => 
{
    const [referralCode, setReferralCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    let navigate = useNavigate();

    useSettingsMountEffect({
        disableNavigationType: true,
        disableHorizontalNavbarAppearance: true,
        disableVerticalNavbarAppearance: true,
        disableHorizontalNavbarShape: true
    });

    const handleReferralChange = (e) => {setReferralCode(e.target.value);};

    function handleSubmit()
    {
        // Check if the form is populated.
        if(referralCode != undefined && referralCode !== "")
        {
            navigate(`/dashboard/${referralCode}`)
        }
        else
        {
            setErrorMessage('Referral is code missing. Please provide it before continuing forward.')
        }   
    }

    const handleKeyPress = (event) => 
    {
        if (event.key === 'Enter') 
        {
            handleSubmit();
        }
    };

    const handleClick = (event) => 
    {
        event.preventDefault(); 
        handleSubmit();
    };

    // const divStyle = {
    //     backgroundImage: `url(${background})`,
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     width: '100% !important',
    //     height: '100% !important'
    // };

    return (
        <div>
            <section className="pb-8">
            <Error heading={`ERROR`} message={errorMessage}/>
                <div className="hero-header-container px-lg-7 px-xxl-3">
                    <Row className="align-items-center">
                        <Col xs={12} lg="auto" className="text-end order-0 order-md-1 order-1">
                            <div className="position-relative p-5 p-md-7 d-lg-none">
                                {/* <div
                                    className="bg-holder banner-bg"
                                    style={{
                                    backgroundImage: `url(${bg23})`,
                                    backgroundSize: 'contain'
                                    }}
                                /> */}
                                <div className="position-relative">
                                    {/* <img
                                    className="w-100 shadow-lg d-dark-none rounded-2"
                                    src={bg31}
                                    alt="hero-header"
                                    /> */}
                                    {/* <img
                                    className="w-100 shadow-lg d-light-none rounded-2"
                                    src={bg30}
                                    alt="hero-header"
                                    /> */}
                                </div>
                            </div>
                            <div className="hero-image-container position-absolute top-0 bottom-0 end-0 d-none d-lg-block">
                                <div className="position-relative h-100 w-100">
                                    <div className="position-absolute h-100 top-0 d-flex align-items-center end-0 hero-image-container-bg">
                                        {/* <img
                                            className="pt-7 pt-md-0 w-100"
                                            src={bg12}
                                            alt="hero-header"
                                        /> */}
                                    </div>
                                    <div className="position-absolute h-100 top-0 d-flex align-items-center end-0">
                                        {/* <img
                                            className="pt-7 pt-md-0 w-100 shadow-lg d-dark-none rounded-2"
                                            src={bg28}
                                            alt="hero-header"
                                        /> */}
                                        {/* <img
                                            className="pt-7 pt-md-0 w-100 shadow-lg d-light-none rounded-2"
                                            src={bg29}
                                            alt="hero-header"
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="text-lg-start text-center pt-8 pb-6 order-0 position-relative">
                            <div>
                                <h1 className="fs-3 fs-lg-2 fs-md-1 fs-lg-2 fs-xl-1 fw-black mb-4">
                                    <span className="text-primary me-3" style={{color: '#43aec0 '}}>Referrals</span>
                                    <br />
                                    made simple
                                    <br />
                                    by Detour Inc.
                                </h1>
                                <p className="mb-5">
                                    Manage your referral code by entering it below
                                </p>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder="6a8s41das1d65a1sd65a1sd"
                                        aria-label="6a8s41das1d65a1sd65a1sd"
                                        aria-describedby="basic-addon2"
                                        value={referralCode}
                                        onChange={handleReferralChange}
                                        onKeyDown={handleKeyPress}
                                    />
                                    <Link onClick={handleClick} className="btn btn-primary">Submit</Link>
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    );
};

export default Home;

