import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import { Navbar } from 'react-bootstrap';
import logo from 'assets/img/icons/LLLogo.png';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarToggleButton from './NavbarToggleButton';
import { Link } from 'react-router-dom';

const NavbarBrand = () => 
{
  const {config: { navbarTopShape, navbarPosition }} = useAppContext();
  const { breakpoints } = useBreakpoints();
  const [imageWidth, setImageWidth] = useState(250);

  useEffect(() => 
  {
    const handleResize = () => {
      if (window.innerWidth <= 768) 
      {
        setImageWidth(150); // Adjust this value as needed for mobile
      } 
      else 
      {
        setImageWidth(250); // Adjust this value as needed for desktop
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => 
    {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="navbar-logo">
      {breakpoints.down('lg') && <NavbarToggleButton />}
      <Navbar.Brand
        as={Link}
        to="/"
        className={classNames({
          'me-1 me-sm-3':
            navbarTopShape === 'slim' || navbarPosition === 'horizontal'
        })}
      >
      {navbarTopShape === 'slim' ? (
        <>
          Leads & Leaders <span className="text-1000 d-none d-sm-inline">slim</span>
        </>
      ) : (
        <div className="d-flex align-items-center">
          <img src={logo} alt="phoenix" style={{ width: imageWidth }} />
        </div>
      )}
      </Navbar.Brand>
    </div>
  );
};

export default NavbarBrand;
