import Avatar from 'components/base/Avatar';
import { Dropdown, Modal, Nav } from 'react-bootstrap';
import avatar57 from 'assets/img/team/40x40/57.webp';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import NineDotMenu from './NineDotMenu';
import { useAppContext } from 'providers/AppProvider';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import NotificationDropdownMenu from './NotificationDropdownMenu';
import ThemeToggler from 'components/common/ThemeToggler';
import { useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import classNames from 'classnames';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignOutButton } from "../../../customcomponents/SignOutButton";
import { SignInButton } from '../../../customcomponents/SignInButton';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { callMsGraph } from '../../../graph';

const NavItems = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const isAuthenticated = useIsAuthenticated();
  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      {/* <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item> */}
      {isAuthenticated ? <SignOutButton /> : <SignInButton />}
      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NavItems;
