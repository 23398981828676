import { useState } from "react";
import { Alert } from "react-bootstrap";

export function renderPhoneNumber(number)
{
    const numericOnly = String(number).replace(/\D/g, '');
    const formattedNumber = `${numericOnly.slice(0, 3)}-${numericOnly.slice(3, 6)}-${numericOnly.slice(6)}`
    return formattedNumber;
}

export function generateRandomString(length) 
{
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < length; i++) 
    {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
    }

    return randomString;  
}


export const DismissableError = ({heading, message}) => 
{
    const [showComponent, setShowComponent] = useState(true);

    if(showComponent === true && String(message).length > 0)
    {
        return (
            <Alert variant="danger" className='text-white' onClose={() => setShowComponent(false)} dismissible>
                <Alert.Heading>{heading}</Alert.Heading>
                <p>
                    {message}
                </p>
            </Alert>
        )
    }
    else
    {
        return(<></>);
    }
}

export const Error = ({heading, message}) => 
{
    const [showComponent, setShowComponent] = useState(true);

    if(showComponent === true && String(message).length > 0)
    {
        return (
            <Alert variant="danger" className='text-white'>
                <Alert.Heading>{heading}</Alert.Heading>
                <p>{message}</p>
            </Alert>
        )
    }
    else
    {
        return(<></>);
    }
}