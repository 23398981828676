import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
// import { Table } from './table';
import { Table, Spinner } from 'react-bootstrap'
import { renderPhoneNumber } from '../functions';

const Codes = () => 
{
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        try 
        {
            const response = await fetch('/codes');
            const result = await response.json();
            setData(result);
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
        finally 
        {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    
    function renderCodes()
    {
        const codeList = [];
        if(data.length === 0)
        {
            codeList.push(<tr><td>There are no codes currently :/</td><td></td><td></td><td></td><td></td><td></td></tr>);
        }
        else
        {
            for(var i=0; i < data.length; i++)
            {
                codeList.push(
                    <tr>
                        <td>{data[i].FirstName}</td>
                        <td>{data[i].LastName}</td>
                        <td>{data[i].Email}</td>
                        <td>{renderPhoneNumber(data[i].PhoneNum)}</td>
                        <td>{data[i].Code}</td>
                        <td>
                            <Link to={`/codes/${data[i].Code}/delete`} className='btn btn-danger'>
                                <FontAwesomeIcon className='me-1' icon={faTrash} />
                            </Link>
                            <Link to={`/dashboard/${data[i].Code}`} className='btn btn-primary' style={{marginLeft: '5px'}}>
                                <FontAwesomeIcon className='me-1' icon={faEye} />
                            </Link>
                        </td>
                    </tr>
                )
            }
        }
        return codeList;
    }

    return (
        <div>
            <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
                <h2 className="mb-0">
                    <span className="me-3">Codes</span>
                </h2>
                <Link to="/codes/new" className="btn btn-primary px-5">
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Create new code
                </Link>
            </div>
            <p>Below is a list of all outstanding referral codes</p>
            {
                isLoading
                ?
                <><Spinner animation="border" variant="primary" /></>
                :
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Code</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderCodes()}
                        </tbody>
                    </Table>
                </>
            }
        </div>
    );
};

export default Codes;

