import React, { Suspense, lazy, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import Footer from 'components/footers/Footer';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
// import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import NavbarVertical from 'navbar/navbarVertical';
import { useAppContext } from 'providers/AppProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import MainLayout from 'layouts/MainLayout';
import useToggleStyle from 'hooks/useToggleStyle';

const FontAwesomeExample = lazy(() => import('pages/modules/components/FontAwesomeExample'));
const FeatherIconsExample = lazy(() => import('pages/modules/components/FeatherIconsExample'));
const UniconsExample = lazy(() => import('pages/modules/components/UniconsExample'));

// CUSTOM COMPONENTS
import Users from './customcomponents/users';
import StandardDashboard from './customcomponents/standardDashboard';
import Home from './customcomponents/home';
import Codes from './customcomponents/codes';
import CreateCode from './customcomponents/createCode';
import CreateUser from './customcomponents/createUser';
import EditUser from './customcomponents/editUser';
import DeleteUser from './customcomponents/deleteUser';
import AdminDashboard from './customcomponents/adminDashboard';
import Welcome from './customcomponents/welcome';
import DeleteCode from './customcomponents/deleteCode';
import Leaderboard from './customcomponents/leaderBoard';
import Referrals from './customcomponents/referrals';
import EditReferral from './customcomponents/editReferral';
import CreateReferral from './customcomponents/createReferral';
import Leads from './customcomponents/leads';
import CreateLead from './customcomponents/createLead';
import DeleteLead from './customcomponents/deleteLead';
import WelcomeComplete from './customcomponents/welcomeComplete';
import CheckAuth from './customcomponents/checkAuth';
import Layout from './customcomponents/backgroundLayout';

// MSAL STUFF
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
const msalInstance = new PublicClientApplication(msalConfig);
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import background from './assets/img/bg/backgroundLarge.jpg';

function App() 
{
    const { config: { navbarPosition } } = useAppContext();
    const { contentClass, footerClass } = useMainLayoutContext();
    const { isStylesheetLoaded } = useToggleStyle();
    //   const { pathname } = useLocation();
    
    const {
        config: { theme, isRTL }
    } = useAppContext();

    const styles = {
        position: 'fixed',top: 0,right: 0,bottom: 0,left: 0,backgroundColor: theme === 'dark' ? '#000' : '#fff'
    }

    const divStyle = {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: '0'
    };

    return (
        <Router>
            <MsalProvider instance={msalInstance}>
                <CheckAuth />
                <AuthenticatedTemplate>
                    {!isStylesheetLoaded 
                    ? 
                    (<div style={styles}/>)
                    :
                    (
                    <Container fluid className="px-0">
                        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (<NavbarVertical />)}
                        {navbarPosition === 'vertical' && <NavbarTopDefault />}
                        {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (<NavbarTopHorizontal />)}
                        {navbarPosition === 'dual' && <NavbarDual />}
                        {/* <div className={classNames(contentClass, 'content', { 'customBackground': location.pathname === '/' })}> */}
                        <Layout>
                            <Routes>
                                {/* Global Routes */}
                                <Route path="/" element={<Home />} />
                                <Route path="/welcome/complete" element={<WelcomeComplete />} />
                                <Route path="/share" element={<Welcome />} />
                                <Route path="/share/:code" element={<Welcome />} />
                                <Route path="/leaderboard" element={<Leaderboard />} />
                                <Route path="/referrals" element={<Referrals />} />
                                <Route path="/referrals/:userID/edit" element={<EditReferral />} />
                                <Route path="/referrals/new" element={<CreateReferral />} />
                                <Route path="/dashboard" element={<StandardDashboard />} />
                                <Route path="/dashboard/:referralCode" element={<StandardDashboard />} />

                                {/* Admin Routes */}
                                <Route path="/admin" element={<AdminDashboard />} />
                                <Route path="/users" element={<Users />} />
                                <Route path="/users/:userID" element={<Users />} />
                                <Route path="/users/:userID/edit" element={<EditUser />} />
                                <Route path="/users/:userID/delete" element={<DeleteUser />} />
                                <Route path="/users/new" element={<CreateUser />} />
                                <Route path="/codes" element={<Codes />} />
                                <Route path="/codes/new" element={<CreateCode />} />
                                <Route path="/codes/:codeID/delete" element={<DeleteCode />} />
                                <Route path="/leads" element={<Leads />} />
                                <Route path="/leads/new" element={<CreateLead />} />
                                <Route path="/leads/:leadID/delete" element={<DeleteLead />} />
                            </Routes>
                            <Footer className={classNames(footerClass, 'position-absolute')} />
                        </Layout>
                        {/* </div> */}
                    </Container>
                    )}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    {!isStylesheetLoaded 
                    ? 
                    (<div style={styles}/>)
                    : 
                    (
                    <Container fluid className="px-0">
                        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (<NavbarVertical />)}
                        {navbarPosition === 'vertical' && <NavbarTopDefault />}
                        {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (<NavbarTopHorizontal />)}
                        {navbarPosition === 'dual' && <NavbarDual />}
                        <Layout>
                            <Routes>
                                {/* Global Routes */}
                                <Route path="/" element={<Home />} />
                                <Route path="/welcome/complete" element={<WelcomeComplete />} />
                                <Route path="/share" element={<Welcome />} />
                                <Route path="/share/:code" element={<Welcome />} />
                                <Route path="/leaderboard" element={<Leaderboard />} />
                                <Route path="/dashboard" element={<StandardDashboard />} />
                                <Route path="/dashboard/:referralCode" element={<StandardDashboard />} />
                            </Routes>
                        </Layout>
                        <Footer className={classNames(footerClass, 'position-absolute')} />
                    </Container>
                    )}
                </UnauthenticatedTemplate>
            </MsalProvider>
        </Router>
    );
}

export default App;