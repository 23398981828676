import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Link } from 'react-router-dom';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SignInButton = () => 
{
  const { instance } = useMsal();
  const handleLogin = (loginType) => 
  {
    if(loginType === "popup") 
    {
      instance.loginPopup(loginRequest).catch((e) => {console.log(e);});
    } 
    else if(loginType === "redirect") 
    {
      instance.loginRedirect(loginRequest).catch((e) => {console.log(e);});
    }
  };

  return (
    <div>
      <Link className='btn btn-outline-primary' onClick={() => handleLogin("redirect")}>
        Login
        &nbsp;
        <FontAwesomeIcon className='me-1' icon={faSignIn} />
      </Link>
    </div>
  );
};