import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Row, Col, Button, Spinner } from 'react-bootstrap';
import {
    faEnvelope,
    faFileExport,
    faFilter,
    faPencil,
    faSearch
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Referrals = () => 
{
    const [statsData, setStatsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');

    const fetchData = async () => 
    {
        try 
        {
            const response = await fetch(`/referrals`);
            const result = await response.json();
            if(result.length == 0) {setMessage('Invalid referral code.')}
            else
            {
                setStatsData(result);
            }
            setIsLoading(false);
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
    };

    useEffect(() => {
        fetchData();
    }, []);

    function renderReferrals()
    {
        const referrals = [];
        if(statsData.length === 0)
        {
            referrals.push(<tr><td>There are no referrals currently :/</td><td></td><td></td><td></td></tr>);
        }
        else
        {
            for(var i=0; i < statsData.length; i++)
            {
                referrals.push(
                    <tr>
                        <td>{statsData[i].UserID}</td>
                        <td>{statsData[i].Name}</td>
                        <td>{statsData[i].LinkClicks}</td>
                        <td>{statsData[i].FormsSubmitted}</td>
                        <td>{statsData[i].SuccessfulReferrals}</td>
                        <td>        
                            <Link to={`/referrals/${statsData[i].UserID}/edit`} className='btn btn-primary'>
                                <FontAwesomeIcon className='me-1' icon={faPencil} />
                                Edit
                            </Link>
                        </td>
                    </tr>
                );
            }
        }
        return referrals;
    }                        

    return (
        <div>
            {/* <Error heading={`ERROR`} message={message}/> */}
            <h2 className="mb-2">Referrals</h2>
            <br></br>
            {
                isLoading
                ?
                <><Spinner animation="border" variant="primary" /></>
                :
                <>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>UserID</th>
                            <th>Name</th>
                            {/* <th>Email Invites Sent</th>
                            <th>Invites Viewed</th> */}
                            <th>Total Clicks</th>
                            <th>Forms Submitted</th>
                            <th>Successful Referrals</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderReferrals()}
                    </tbody>
                </Table>
                </>
            }
        </div>
    );
};

export default Referrals;

