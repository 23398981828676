import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Form } from 'react-bootstrap'

const CreateUser = () => 
{
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    
    const handleFirstNameChange = (e) => {setFirstName(e.target.value);};
    const handleLastNameChange = (e) => {setLastName(e.target.value);};
    const handleEmailChange = (e) => {setEmail(e.target.value);};
    const handlePhoneChange = (e) => {setPhone(e.target.value);};
    const handleStreetChange = (e) => {setStreetAddress(e.target.value);};
    const handleCityChange = (e) => {setCity(e.target.value);};
    const handleStateChange = (e) => {setState(e.target.value);};
    const handleZipChange = (e) => {setZipCode(e.target.value);};
    
    let nav = useNavigate();

    async function postData() 
    {
        var body = JSON.stringify({ 
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            streetAddress: streetAddress,
            city: city,
            state: state,
            zipCode: zipCode
        });

        await fetch('/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        }).then(response => response.json()).then(data => 
        {
            nav(`/users`);
            console.log('Post successful', data);
        }).catch(error => 
        {
            console.log('Error posting data', error);
        }); 
    }

    const handleClick = (e) => 
    {
        e.preventDefault();
        postData();
    }

    return (
        <div>
            <Row className="g-4">
                <Col xs={12} xxl={6}>
                    <h2 className="mb-2">Create A New User</h2>
                    <h5 className="text-700 fw-semi-bold">
                        {/* View the most recent statistics for your code */}
                    </h5>
                    <br></br>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="John" value={firstName} onChange={handleFirstNameChange} />
                        <br></br>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Doe" value={lastName} onChange={handleLastNameChange}/>
                        <br></br>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="name@example.com" value={email} onChange={handleEmailChange} />
                        <br></br>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="tel" placeholder="123-456-7890" value={phone} onChange={handlePhoneChange} />
                        <br></br>
                        <Form.Label>Street Address</Form.Label>
                        <Form.Control type="text" placeholder="123 Main St." value={streetAddress} onChange={handleStreetChange} />
                        <br></br>
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="Chicago" value={city} onChange={handleCityChange} />
                        <br></br>
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" placeholder="IL" value={state} onChange={handleStateChange} />
                        <br></br>
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control type="number" placeholder="60007" value={zipCode} onChange={handleZipChange} />
                    </Form.Group>
                </Col>
            </Row>
            <br></br>
            <Link to="/users" className="btn btn-outline-info">Back</Link>
            <Link className="btn btn-outline-info" style={{marginLeft: '5px'}} onClick={handleClick}>Submit</Link>
        </div>
    );
};

export default CreateUser;

