import React, { useEffect, useState, PureComponent } from "react";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Link } from "react-router-dom";
import ReportDetailsChart from 'components/charts/e-charts/ReportDetailsChart';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import ReportDetailsHeader from 'components/modules/crm/report-details/ReportDetailsHeader';
import DealsReportTable, {dealsReportColumns} from 'components/tables/DealsReportTable';
import ReportDetailsTable from 'components/tables/ReportDetailsTable';
import { defaultBreadcrumbItems } from 'data/commonData';
import { dealsReportData } from 'data/crm/reportsData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Card, Col, Row, Table, Spinner } from 'react-bootstrap';

const Leaderboard = () => 
{
    const [chartDimensions, setChartDimensions] = useState({ width: 200, height: 200 });
    const [statsData, setStatsData] = useState(null);
    const [allStatsData, setAllStatsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');

    const fetchData = async () => 
    {
        try 
        {
            // Fetch stats for bar graph
            const response = await fetch(`/leaderboard/stats`);
            const result = await response.json();
            if(result.length == 0) {setMessage('Could not request statistics. Error.')}
            else{setStatsData(result);}
            
            // Fetch stats for table
            const response2 = await fetch(`/leaderboard/stats/all`);
            const result2 = await response2.json();
            if(result2.length == 0) {setMessage('Could not request statistics. Error.')}
            else{setAllStatsData(result2);}


            setIsLoading(false);
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
    };

    useEffect(() => {
        fetchData();
    }, []);

    const table = useAdvanceTable({
        data: dealsReportData,
        columns: dealsReportColumns,
        pageSize: 10,
        selection: true,
        pagination: true,
        sortable: true
    });

    const dataaaa = [
        {
            Dave: 2,
            Shaun: 1,
            Stephanie: 3
        }
    ];

    function renderRows()
    {
        var finalData = [];
        for(var i=0; i < allStatsData.length; i++)
        {
            finalData.push(
                <tr>
                    <td>{`${allStatsData[i].FirstName} ${String(allStatsData[i].LastName[0])}`}</td>
                    <td>{allStatsData[i].FormsSubmitted}</td>
                    <td>{allStatsData[i].SuccessfulReferrals}</td>
                </tr>
            );
        }
        return finalData;
    }

    function generateChart()
    {
        var finalData = [];
        for(var i=0; i < statsData.length; i++)
        {
            var tmpData = {
                name: `${statsData[i].FirstName} ${String(statsData[i].LastName[0])}`,
                'Forms Submitted': statsData[i].FormsSubmitted
            }
            finalData.push(tmpData);
        }


        return (
            <div style={{ width: '100%', height: '100%' }}>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        data={finalData}
                        margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Forms Submitted" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        )
    }

    return (
        <div>
        {
            isLoading
            ?
            <><Spinner animation="border" variant="primary" /></>
            :
            <>
            <AdvanceTableProvider {...table}>
                <div className="pb-9">
                    <h2 className="mb-4">Leaderboard</h2>
                    {/* <ReportDetailsHeader /> */}
                    <Row className="gy-5">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h5>Available Prizes</h5>
                                    <ul>
                                        <li>5 Referrals  - $50 Amazon Gift Card</li>
                                        <li>10 Referrals - $150 Amazon Gift Card</li>
                                        <li>15 or more Referrals - Secret Prize</li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="gy-5">
                        <Col xs={12} lg={5} xxl={4}>
                            <Card style={{ height: '100%' }} id="parent-container">
                            <center>Top 5 Leaders</center>
                                <Card.Body style={{ paddingRight: '0', paddingLeft: '0' }}>
                                    {generateChart()}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} lg={7} xxl={8}>
                            <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Forms Submitted</th>
                                    <th>Successful Referrals</th>
                                </tr>
                            </thead>
                            <tbody>{renderRows()}</tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </AdvanceTableProvider>
            </>
        }
        </div>
    );
};

export default Leaderboard;

