import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form, Spinner, Button } from 'react-bootstrap'
import { Error } from '../functions';

const EditUser = () => 
{
    const { userID } = useParams();

    // Operational state fields
    const [isLoading, setIsLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [message, setMessage] = useState('');

    // Data collection state fields
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');

    // Use for changing pages
    let nav = useNavigate();

    const handleFirstNameChange = (e) => {setFirstName(e.target.value);};
    const handleLastNameChange = (e) => {setLastName(e.target.value);};
    const handleEmailChange = (e) => {setEmail(e.target.value);};
    const handlePhoneChange = (e) => {setPhone(e.target.value);};
    const handleStreetChange = (e) => {setStreetAddress(e.target.value);};
    const handleCityChange = (e) => {setCity(e.target.value);};
    const handleStateChange = (e) => {setState(e.target.value);};
    const handleZipChange = (e) => {setZipCode(e.target.value);};

    const fetchData = async () => 
    {
        try 
        {
            const response = await fetch(`/users/${userID}`);
            const result = await response.json();

            // Initialize all other variables
            setFirstName(result[0].FirstName)
            setLastName(result[0].LastName)
            setEmail(result[0].Email)
            setPhone(result[0].PhoneNum)
            setStreetAddress(result[0].Street)
            setCity(result[0].City)
            setState(result[0].State)
            setZipCode(result[0].Zip)
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
        finally 
        {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    async function putData() 
    {
        var body = JSON.stringify({ 
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            streetAddress: streetAddress,
            city: city,
            state: state,
            zipCode: zipCode
        });

        await fetch(`/users/${userID}/edit`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        }).then(response => response.json()).then(data => 
        {
            setButtonLoading(false);
            if(data.error){setMessage(data.message)}
            else
            {
                nav('/users');
            }
        }).catch(error => 
        {
            console.log('Error posting data', error);
        }); 
    }

    const handleClick = (e) => 
    {
        e.preventDefault();
        setButtonLoading(true);
        putData();
    }

    return (
        <div>
            {
                isLoading
                ?
                <>
                <Spinner animation="border" variant="primary" />
                </>
                :
                <>
                <Row className="g-4">
                    <Col xs={12} xxl={6}>
                        <Error heading={`ERROR`} message={message}/>
                        <h2 className="mb-2">Edit A User</h2>
                        <h5 className="text-700 fw-semi-bold">
                            {/* View the most recent statistics for your code */}
                        </h5>
                        <br></br>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text"  value={firstName} onChange={handleFirstNameChange} />
                            <br></br>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" value={lastName} onChange={handleLastNameChange}/>
                            <br></br>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" value={email} onChange={handleEmailChange} />
                            <br></br>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="tel" value={phone} onChange={handlePhoneChange} />
                            <br></br>
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control type="text" value={streetAddress} onChange={handleStreetChange} />
                            <br></br>
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" value={city} onChange={handleCityChange} />
                            <br></br>
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" alue={state} onChange={handleStateChange} />
                            <br></br>
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control type="number" value={zipCode} onChange={handleZipChange} />
                        </Form.Group>
                    </Col>
                </Row>
                </>
            }
            <br></br>
            <Link to="/users" className="btn btn-outline-info">Back</Link>
            <Link className="btn btn-outline-info" style={{marginLeft: '5px'}} onClick={handleClick}>Submit</Link>
            <br></br>
            <br></br>
            {
                buttonLoading
                ?
                <Spinner animation="border" variant="primary" />
                :
                <></>
            }
        </div>
    );
};

export default EditUser;

