import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Form, Spinner } from 'react-bootstrap';

const WelcomeComplete = () => 
{
    return (
        <div>            
            <Row className="align-items-center">
                <Col xs={12} lg={6} className="text-lg-start text-center pt-8 pb-6 order-0 position-relative">
                    <div>
                        <h1 className="fs-3 fs-lg-2 fs-md-1 fs-lg-2 fs-xl-1 fw-black mb-4">
                            <span className="text-primary me-3">Thank you!</span>
                        </h1>
                        <h5>A member from the Detour Inc. team will reach out to you shortly.</h5>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default WelcomeComplete;

