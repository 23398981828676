import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Table, Spinner } from 'react-bootstrap'
import { renderPhoneNumber } from '../functions';

const Users = () => 
{
    const { userID } = useParams();

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => 
    {
        try 
        {
            const response = await fetch('/users');
            const result = await response.json();
            setData(result);
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
        finally 
        {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    function renderUsers()
    {
        const userList = [];
        for(var i=0; i < data.length; i++)
        {
            userList.push(
                <tr>
                    <td>{data[i].FirstName}</td>
                    <td>{data[i].LastName}</td>
                    <td>{data[i].Email}</td>
                    <td>{renderPhoneNumber(data[i].PhoneNum)}</td>
                    <td>
                        <Link to={`/users/${data[i].ID}/edit`} className='btn btn-primary'>
                            <FontAwesomeIcon className='me-1' icon={faPencil} />
                            Edit
                        </Link>
                        <Link to={`/users/${data[i].ID}/delete`} className='btn btn-danger' style={{marginLeft: '5px'}}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Link>
                    </td>
                </tr>
            )
        }
        return userList;
    }

    return (
        <div>
            <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
                <h2 className="mb-0">
                    <span className="me-3">Users</span>
                </h2>
                <Link to="/users/new" className="btn btn-primary px-5">
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Create new user
                </Link>
            </div>
            <p>Below is a list of all existing users</p>
            {
                isLoading
                ?
                <><Spinner animation="border" variant="primary" /></>
                :
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderUsers()}
                        </tbody>
                    </Table>
                </>
            }
        </div>
    );
};

export default Users;

