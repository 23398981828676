import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Row, Col, Form, Spinner, Button } from 'react-bootstrap'
import { Error } from '../functions';

const EditReferral = () => 
{
    const { userID } = useParams();

    // Operational state fields
    const [isLoading, setIsLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [message, setMessage] = useState('');

    // Data collection state fields
    const [invitesSent, setInvitesSent] = useState('');
    const [invitesViewed, setInvitesViewed] = useState('');
    const [totalClicks, setTotalClicks] = useState('');
    const [formsSubmitted, setFormsSubmitted] = useState('');
    const [successful, setSuccessful] = useState('');
    
    // Use for changing pages
    let nav = useNavigate();

    const handleinvitesSentChange = (e) => {setInvitesSent(e.target.value);};
    const handleInvitesViewedChange = (e) => {setInvitesViewed(e.target.value);};
    const handletotalClicksChange = (e) => {setTotalClicks(e.target.value);};
    const handleformsSubmittedChange = (e) => {setFormsSubmitted(e.target.value);};
    const handlesuccessfulChange = (e) => {setSuccessful(e.target.value);};

    const fetchData = async () => 
    {
        try 
        {
            const response = await fetch(`/referrals/${userID}`);
            const result = await response.json();

            // Initialize all other variables
            // setInvitesSent(result[0].InvitesSent)
            // setInvitesViewed(result[0].InvitesViewed)
            setTotalClicks(result[0].LinkClicks)
            setFormsSubmitted(result[0].FormsSubmitted)
            setSuccessful(result[0].SuccessfulReferrals)
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
        finally 
        {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    async function putData() 
    {
        var body = JSON.stringify({ 
            totalClicks: totalClicks,
            formsSubmitted: formsSubmitted,
            successful: successful
        });

        console.log(JSON.stringify(body))

        await fetch(`/referrals/${userID}/edit`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        }).then(response => response.json()).then(data => 
        {
            setButtonLoading(false);
            if(data.error){setMessage(data.message)}
            else
            {
                nav('/referrals');
            }
        }).catch(error => 
        {
            console.log('Error posting data', error);
        }); 
    }

    const handleClick = (e) => 
    {
        e.preventDefault();
        setButtonLoading(true);
        putData();
    }

    return (
        <div>
            {
                isLoading
                ?
                <>
                <Spinner animation="border" variant="primary" />
                </>
                :
                <>
                <Row className="g-4">
                    <Col xs={12} xxl={6}>
                        <Error heading={`ERROR`} message={message}/>
                        <h2 className="mb-2">Edit A Referral</h2>
                        <h5 className="text-700 fw-semi-bold">
                            {/* View the most recent statistics for your code */}
                        </h5>
                        <br></br>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            {/* <Form.Label>Invites Sent</Form.Label>
                            <Form.Control type="number" placeholder="15" value={invitesSent} onChange={handleinvitesSentChange} />
                            <br></br>
                            <Form.Label>Invites Viewed</Form.Label>
                            <Form.Control type="number" placeholder="5" value={invitesViewed} onChange={handleInvitesViewedChange}/>
                            <br></br> */}
                            <Form.Label>Total Clicks</Form.Label>
                            <Form.Control type="number" placeholder="3" value={totalClicks} onChange={handletotalClicksChange} />
                            <br></br>
                            <Form.Label>Forms Submitted</Form.Label>
                            <Form.Control type="number" placeholder="2" value={formsSubmitted} onChange={handleformsSubmittedChange} />
                            <br></br>
                            <Form.Label>Successful Referrals</Form.Label>
                            <Form.Control type="number" placeholder="3" value={successful} onChange={handlesuccessfulChange} />
                        </Form.Group>
                    </Col>
                </Row>
                </>
            }
            <br></br>
            <Link to="/referrals" className="btn btn-outline-info">Back</Link>
            <Link className="btn btn-outline-info" style={{marginLeft: '5px'}} onClick={handleClick}>Submit</Link>
            <br></br>
            <br></br>
            {
                buttonLoading
                ?
                <Spinner animation="border" variant="primary" />
                :
                <></>
            }
        </div>
    );
};

export default EditReferral ;

