import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CreateReferral = () => {
    return (
        <div>CreateReferral</div>
    );
};

export default CreateReferral;

