import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Spinner } from 'react-bootstrap';
import { renderPhoneNumber } from '../functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';


const Leads = () => 
{
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => 
    {
        try 
        {
            const response = await fetch('/leads');
            const result = await response.json();
            setData(result);
        } 
        catch (error) 
        {
            console.error('Error fetching data:', error);
        } 
        finally 
        {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    function renderLeads()
    {
        const leadList = [];
        if(data.length === 0)
        {
            leadList.push(
                <tr>
                    <td>There are no leads currently :/</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            );
        }
        else
        {
            for(var i=0; i < data.length; i++)
            {
                leadList.push(
                    <tr>
                        <td>{data[i].Name}</td>
                        <td>{`${data[i].FirstName} ${String(data[i].LastName)[0]}`}</td>
                        <td>{renderPhoneNumber(data[i].Phone)}</td>
                        <td>{data[i].Email}</td>
                        <td>{data[i].Industry}</td>
                        <td>{data[i].Budget}</td>
                        <td>{String(data[i].Deadline).split("T")[0]}</td>
                        <td>{data[i].WorkCategory}</td>
                        <td>{data[i].OtherInfo}</td>
                        <td>
                            <Link to={`/leads/${data[i].ID}/delete`} className='btn btn-danger'>
                                <FontAwesomeIcon className='me-1' icon={faTrash} />
                            </Link>
                        </td>
                    </tr>
                )
            }
        }
        return leadList;
    }

    return (
        <div>
            <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
                <h2 className="mb-0">
                    <span className="me-3">Leads</span>
                </h2>
                <Link to="/leads/new" className="btn btn-primary px-5">
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Create new lead
                </Link>
            </div>
            <p>Below is a list of all outstanding leads</p>
            {
                isLoading
                ?
                <><Spinner animation="border" variant="primary" /></>
                :
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Referred By</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Industry</th>
                                <th>Budget</th>
                                <th>Deadline</th>
                                <th>Work Category</th>
                                <th>Other</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderLeads()}
                        </tbody>
                    </Table>
                </>
            }
        </div>
    );
};

export default Leads;

